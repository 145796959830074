import React from "react";
import "./landing-page.scss";
import { useTranslation } from "react-i18next";

export const LandingPage = () => {
  const { t } = useTranslation();

  return (
    <div className="landing-page" id="Home">
      <div className="description">
        <h1>
          {t("header_one")} <span>{t("header_two")}</span>
        </h1>
        <a href="#Footer">
          <button>{t("btn")}</button>
        </a>
      </div>
    </div>
  );
};
