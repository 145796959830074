import React from "react";
import { useTranslation } from "react-i18next";
import "../styles/read-more.scss";
import { Link } from "react-router-dom";
import AnimatedLock from "../components/animatedLock/AnimatedLock";
import { FaArrowLeft } from 'react-icons/fa';
const ReadMore = ({ titleKey, descriptionKeys }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="read-more-container">
        <Link to="/">
        <button><FaArrowLeft /> GO BACK</button>
        </Link>
        <div className="white-glass">
          <div className="glass-text-container">
            <span>{t(titleKey)}</span>
            <p>{t(descriptionKeys[0])}</p>
            <p>{t(descriptionKeys[1])}</p>
            <p>{t(descriptionKeys[2])}</p>
          </div>
        </div>
          <AnimatedLock />
      </div>
    </>
  );
};

export default ReadMore;
