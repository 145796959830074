import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MainPage } from "./pages/MainPage";
import ReadMore from "./pages/ReadMore";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route
            path="/consulting"
            element={
              <ReadMore
                titleKey="consulting"
                descriptionKeys={[
                  "consulting_description1",
                  "consulting_description2",
                  "consulting_description3",
                ]}
              />
            }
          />
          <Route
            path="/personal-protection"
            element={
              <ReadMore
                titleKey="personal_protection"
                descriptionKeys={[
                  "personal_protection_description1",
                  "personal_protection_description2",
                  "personal_protection_description3",
                ]}
              />
            }
          />
          <Route
            path="/infrastructure-protection"
            element={
              <ReadMore
                titleKey="infrastructure_protection"
                descriptionKeys={[
                  "infrastructure_protection_description1",
                  "infrastructure_protection_description2",
                  "infrastructure_protection_description3",
                ]}
              />
            }
          />
          <Route
            path="/it-protection"
            element={
              <ReadMore
                titleKey="infrastructure_and_information"
                descriptionKeys={[
                  "infrastructure_and_information_description1",
                  "infrastructure_and_information_description2",
                  "infrastructure_and_information_description3",
                ]}
              />
            }
          />
          <Route
            path="/crisis-management"
            element={
              <ReadMore
                titleKey="crisis_management"
                descriptionKeys={[
                  "crisis_management_description1",
                  "crisis_management_description2",
                  "crisis_management_description3",
                ]}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
