import React from "react";
import { Link } from "react-router-dom";
import "./card.scss";
const Card = ({ title, description, backgroundImage, link, btn }) => {
  const cardStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    minHeight: "300px",
  };

  return (
    <div className="card">
      <div style={cardStyle}>
        <div className="card-inside">
          <div className="text-container">
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
          <Link to={link}>
            <button>{btn}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
