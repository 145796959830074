import React from "react";
import "./animated-lock.scss";
import lockTop from "../../img/svg/grey-lock-top.svg";
import lockBotom from "../../img/svg/grey-lock-bottom.svg";
const AnimatedLock = () => {
  return (
    <div className="animated">
      <div className="lock-two">
          <img src={lockTop} alt="lock-top" />
          <img src={lockBotom} alt="lock-bottom" />
      </div>
    </div>
  );
};

export default AnimatedLock;
