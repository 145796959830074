import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./about-us.scss";

const AboutUs = () => {
  const { t } = useTranslation();

  const [isOfferDescriptionExpanded, setOfferDescriptionExpanded] =
    useState(false);
  const [isVisionDescriptionExpanded, setVisionDescriptionExpanded] =
    useState(false);

  const renderText = (textKeys, isExpanded, setExpanded) => {
    const maxLength = 350;
    if (isExpanded) {
      return textKeys.map((key, index) => <p key={index}>{t(key)}</p>);
    } else {
      const firstText = t(textKeys[0]);
      return (
        <>
          <span>{firstText.substring(0, maxLength)}...</span>
            <div className="see-more" onClick={() => setExpanded(true)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.92871 17.0714L17.0711 2.92896M17.0711 2.92896V17.0714M17.0711 2.92896H2.92871" stroke="currentColor" stroke-width="2"></path>
              </svg>
            </div>
        </>
      );
    }
  };

  const offerDescriptionKeys = [
    "offer_description_one",
    "offer_description_two",
    "offer_description_three",
    "offer_description_four",
    "offer_description_five",
    "offer_description_six",
    "offer_description_seven",
  ];

  const visionDescriptionKeys = [
    "glass_description_one",
    "glass_description_two",
    "glass_description_three",
    "glass_description_four",
    "glass_description_five",
    "glass_description_six",
    "glass_description_seven",
    "glass_description_eight",
  ];

  return (
    <section className="about-us" id="AboutUs">
      <div className="white-glass">
        <div className="glass-text">
          <h3>{t("about_us")}</h3>
          <div className="text">
            {renderText(
              offerDescriptionKeys,
              isOfferDescriptionExpanded,
              setOfferDescriptionExpanded
            )}
          </div>
        </div>
      </div>
      <div className="white-glass">
        <div className="glass-text">
          <h3>{t("vision")}</h3>
          <div className="text">
            {renderText(
              visionDescriptionKeys,
              isVisionDescriptionExpanded,
              setVisionDescriptionExpanded
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
