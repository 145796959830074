import React from "react";
import { useState, useEffect, useRef } from "react";
import { Navbar } from "../components/navbar/Navbar";
import "../styles/main-page.scss";
import { Footer } from "../components/footer/Footer";
import { LandingPage } from "../modules/LandingPage/LandingPage";
import AboutUs from "../modules/AbousUs/AboutUs";
import { Offer } from "../modules/Offer/Offer";
import LockTop from "../img/svg/grey-lock-top.svg";
import LockBotom from "../img/svg/grey-lock-bottom.svg";
import { useSpring, animated } from "@react-spring/web";

export const MainPage = () => {
  const [scrollY, setScrollY] = useState(0);
  const lockRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [{ topY, bottomY }, set] = useSpring(() => ({
    topY: -25,
    bottomY: 25,
    config: {
      tension: 170,
      friction: 26,
    },
  }));

  useEffect(() => {
    const divisor = 50;
    const topPosition = -25 + scrollY / divisor;
    const bottomPosition = 25 - scrollY / divisor;
  
    set({ topY: topPosition, bottomY: bottomPosition });
  }, [scrollY, set]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPositionFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
      
      const screenWidth = window.innerWidth;
      
      let newTop;
      if (scrollPositionFromBottom <= 200) {
        if (screenWidth <= 600) {
          newTop = "100px";
        } else if (screenWidth <= 1600) {
          newTop = "-50px";
        } else {
          newTop = "140px";
        }
      } else {
        newTop = "140px";
      }
  
      if (lockRef.current) lockRef.current.style.top = newTop;
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <Navbar />
      <LandingPage />
      <div className="lock" ref={lockRef}>
        <animated.img
          src={LockTop}
          style={{ transform: topY.to((y) => `translateY(${y}px)`) }}
          alt="lock-top"
        />
        <animated.img
          src={LockBotom}
          style={{ transform: bottomY.to((y) => `translateY(${y}px)`) }}
          alt="lock-top"
        />
      </div>
      <AboutUs />
      <Offer />
      <Footer />
    </>
  );
};
