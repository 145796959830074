import React from "react";
import Card from "../card/Card";
import CardOne from "../../img/cardsImg/doradztwo-zarzadzanie-bezpieczenstwem.png";
import CardTwo from "../../img/cardsImg/ochrona-infrastruktury-krytycznej.png";
import CardOThree from "../../img/cardsImg/ochrona-osobista.png";
import CardFour from "../../img/cardsImg/ochrona-infrastruktury-informatycznej.png";
import CardFive from "../../img/cardsImg/zarzadzanie-kryzysowe.png";
import "./card-container.scss";
import { useTranslation } from "react-i18next";

const CardsContainer = () => {
  const { t } = useTranslation();

  const cardData = [
    {
      title: t("consulting"),
      description: t("card_consulting_description"),
      backgroundImage: CardOne,
      link: "consulting",
      btn: t("learn_more"),
    },
    {
      title: t("personal_protection"),
      description: t("card_personal_protection_description"),
      backgroundImage: CardOThree,
      link: "/personal-protection",
      btn: t("learn_more"),
    },
    {
      title: t("infrastructure_protection"),
      description: t("card_infrastructure_protection_description"),
      backgroundImage: CardTwo,
      link: "/infrastructure-protection",
      btn: t("learn_more"),
    },
    {
      title: t("infrastructure_and_information"),
      description: t("card_infrastructure_and_information_description"),
      backgroundImage: CardFour,
      link: "/it-protection",
      btn: t("learn_more"),
    },
    {
      title: t("crisis_management"),
      description: t("card_crisis_management_description"),
      backgroundImage: CardFive,
      link: "/crisis-management",
      btn: t("learn_more"),
    },
  ];
  
  return (
    <div className="card-container">
      {cardData.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          description={card.description}
          backgroundImage={card.backgroundImage}
          link={card.link}
          btn={card.btn}
        />
      ))}
    </div>
  );
};

export default CardsContainer;
