import React, { useState } from "react";
import "./offer.scss";
import CardsContainer from "../../components/CardsContainer/CardsContainer";
import { useTranslation } from "react-i18next";

export const Offer = () => {
  const { t } = useTranslation();

  const [isOfferTextExpanded, setOfferTextExpanded] = useState(false);

  const renderText = (textKeys, isExpanded) => {
    const maxLength = 214;
    if (isExpanded) {
      return textKeys.map((key, index) => <p key={index}>{t(key)}</p>);
    } else {
      const firstText = t(textKeys[0]);
      return (
        <>
          <p>{firstText.substring(0, maxLength)}...</p>
          <p className="read-more" onClick={() => setOfferTextExpanded(true)}>
            {t("read_more")}
          </p>
        </>
      );
    }
  };

  const offerTextKeys = [
    "offer_text_one",
    "offer_text_two",
    "offer_text_three",
  ];

  return (
    <section className="offer" id="OurOffer">
      <div className="white-glass">
        <div className="glass-text">
          <span>{t("offer")}</span>
          <div className="text">
            {renderText(offerTextKeys, isOfferTextExpanded)}
            </div>
        </div>
        <CardsContainer />
      </div>
    </section>
  );
};
