import React from "react";
import logo from "../../img/logo-white-03.svg";
import "./footer.scss";
import { MdMail } from "react-icons/md";
export const Footer = () => {
  return (
    <footer id="Footer">
      <div className="footer-container">
        <img src={logo} alt="logo" />
        <div className="contact">
          <div>
            <div className="lines">
              <div className="short-line"></div>
              <div className="long-line"></div>
            </div>
            <span>A: AKS GLOBAL SP Z O.O</span>
            <span>ul. Młodzieńcza 1/3 03-655 Warszawa</span>
            <span>NIP: 1182268268</span>
            <span>KRS: 0001054962</span>
          </div>
          <div>
            <div className="lines">
              <div className="short-line"></div>
              <div className="long-line"></div>
            </div>
            <span>info@aks.global</span>
            <span>Działalność ochrony osób i mienia : L-0261/23</span>
          </div>
        </div>
      </div>
    </footer>
  );
};
