import React, { useState, useEffect } from "react";
import logo from "../../img/logo-03.svg";
import "./navbar.scss";
import { FaBars } from "react-icons/fa";
import { Toggle } from "../switch/Toggle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [scroll, setScroll] = useState(false);

  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setScroll(currentScrollY > 150);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const { t } = useTranslation();

  return (
    <nav className={`navbar ${scroll ? "scrolled" : ""}`}>
      <div className="nav-content">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="toggle">
        <div className="menu">
          <ul style={{ justifyContent: "flex-end" }}>
            <li>
              <a href="#AboutUs">{t("about_us")}</a>
            </li>
            <li>
              <a href="#OurOffer">{t("offer")}</a>
            </li>
            <li>
              <a href="#Footer">{t("contact")}</a>
            </li>
          </ul>
        </div>
        <Toggle />
        <FaBars className="menu-toggle" onClick={handleMenu} />
      </div>
      {openMenu && (
        <div className="drop_down_menu">
          <ul>
            <li>
              <a href="#AboutUs">{t("about_us")}</a>
            </li>
            <li>
              <a href="#OurOffer">{t("offer")}</a>
            </li>
            <li>
              <a href="#Footer">{t("contact")}</a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};
